<template>
    <v-container>
        <v-layout row wrap class="text-xs-center pt-0 pb-3">
            <v-flex xs12>
                <h1>
                    Massage
                </h1>
            </v-flex>
            <v-flex xs12 fill-height class="text-xs-left pt-3 mt-3 ">
                <v-container class="ma-0 pa-0 hidden-sm-and-down">
                    <v-layout row wrap>
                        <v-flex xs8>
                            <br>
                            <h2>Ursprung</h2><br />
                            <br>
                            <p>Die Massage im weitesten Sinne ist eines der ältesten Heilmittel der Menschheit.
                                Franz Kirchberg hat dies in seinem Buch „Handbuch der Massage und Heilgymnastik“ 1926 treffend formuliert:</p>
                            <p>&#8222;Wie jeder Mensch instinktiv eine geschwollene und deshalb schmerzende
                                oder gestossene Stelle seines Körpers reibt oder drückt und so versucht,
                                den durch die Spannung verursachten Schmerz zu mindern,
                                so wird dieses instinktive Mittel wohl auch als Heilmittel zu allen Zeiten angewandt worden sein.&#8220;</p>
                        </v-flex>
                        <v-flex xs4>
                            <br>
                            <v-img contain :src="require('@/assets/massage_stirn.jpg')" ></v-img>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-container class="ma-0 pa-0 hidden-md-and-up">
                    <v-layout row wrap>
                        <v-flex xs12>
                            <h2>Ursprung</h2>
                            <br />
                            <p>Die Massage im weitesten Sinne ist eines der ältesten Heilmittel der Menschheit. Franz Kirchberg hat dies in seinem Buch treffend formuliert:</p>
                            <p>&#8222;Wie jeder Mensch instinktiv eine geschwollene und deshalb schmerzende oder gestossene Stelle seines Körpers reibt oder drückt und so versucht,
                                den durch die Spannung verursachten Schmerz zu mindern, so wird dieses instinktive Mittel wohl auch als Heilmittel zu allen Zeiten angewandt worden sein.&#8220;</p>
                            <p><br />&#8211; Franz Kirchberg: Handbuch der Massage und Heilgymnastik (1926)</p><br />
                        </v-flex>
                    </v-layout>
                </v-container>
                <br>
                <h2>Entwicklung</h2><br />
                <p>
                    Die gezielte Anwendung von Massagen zur Heilung hat ihren Ursprung im Osten Afrikas und in Asien
                    (Ägypten, China, Persien). Die ersten Erwähnungen finden sich beim Chinesen Huáng Dì,
                    der bereits 2600 v. Chr. Massagehandgriffe und gymnastische Übungen beschreibt.
                    In Verbindung mit ätherischen Ölen und Kräutern gibt es auch frühe Nachweise in der indischen Ayurveda.
                    Über den griechischen Arzt Hippokrates (460-375 v. Chr.) gelangt die Massage nach Europa.
                    Hier spielt sie eine wichtige Rolle bei der Rehabilitation der Gladiatoren.
                    Hippokrates erkundet und vertieft die Geheimnisse der Massage und schreibt seine Erkenntnisse und Tipps zur Anwendung nieder.
                    Der zweite bedeutende Arzt der Antike, der Römer Claudius Galenus (129-199 n. Chr.),
                    nahm sich ebenfalls der manuellen Therapie an und schrieb unzählige Abhandlungen über die von ihm entworfenen Massageformen
                    und bei welchen Erkrankungen diese anzuwenden seien. Trotz seines Einflusses, der bis weit in das Mittelalter reicht,
                    verliert die Gesellschaft das Interesse an Massagen und anderen Präventions- und Therapiemaßnahmen.
                </p>
                <br>
                <h2>Wiederentdeckung </h2>
                <br>
                <p>
                    Erst im 16. Jahrhundert wurde die Massage durch den Arzt und Alchimisten Paracelsus (1493-1541)
                    wieder Thema der Medizin. Allerdings sträubte dieser sich gegen die Lehren des Galenus, insbesondere
                    gegen die Viersäftelehre, und machte sich damit unter seinen Kollegen viele Feinde.
                    Es brauchte einen weiteren Arzt, den Franzosen Ambroise Paré (1510-1590),
                    um die Massage in der modernen Medizin zu etablieren.
                    Er verwendet die Massage als Rehabilitationstherapie nach Operationen
                </p>
            </v-flex>
        </v-layout>
    </v-container>

</template>

<script>
    export default {
        name: "Massagen",
        metaInfo () {
            return {
                content: 'Ursprung: ' +
                    'Die Massage im weitesten Sinne ist eines der ältesten Heilmittel der Menschheit. ' +
                    'Entwicklung: Die gezielte Anwendung von Massagen zur Heilung hat ihren Ursprung ' +
                    'im Osten Afrikas und in Asien (Ägypten, China, Persien).'
            }
        }
    }

</script>

<style scoped>

</style>